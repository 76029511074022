<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="
          edit = true;
          add = false;
        "
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-autocomplete
          :value="groupId"
          @change="groupIdChange"
          :items="idList"
          item-value="value"
          label="グループID"
          prepend-icon="mdi-file-table"
          outlined
          :readonly="!canadd()"
          :rules="[Rules.Required]"
        ></v-autocomplete>
        <v-text-field
          v-model="groupName"
          label="グループ名"
          prepend-icon="mdi-file-table"
          outlined
          :readonly="canadd()"
          :rules="[Rules.Required, groupNameRule]"
        ></v-text-field>
        <!--table>
          <thead>
            <tr>
              <th v-for="(item, i) in headers" :key="i">
                {{item}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tokuiList" :key="i">
              <td>
                <v-autocomplete
                  v-model="tokuiList[i]"
                  :items="tokui"
                  :rules="[Rules.Required, duplicate, ckbnRule]"
                  item-text="name"
                  item-value="name"
                  prepend-icon="mdi-close"
                  @click:prepend="clickDelRow(i)"
                  return-object
                ></v-autocomplete>
              </td>
              <td>
                <v-text-field
                  v-model="tokuiList[i].ckbn"
                  class="mr-5"
                  required
                  :readonly="true"
                  :rules="[Rules.Required]"
                />
              </td>
            </tr>
          </tbody>
        </table!-->
        <v-row no-gutters v-for="(item, i) in tokuiList" :key="i">
          <v-autocomplete
            v-model="tokuiList[i]"
            :items="tokui"
            label="得意先"
            :rules="[Rules.Required, duplicate, ckbnRule, tokuiIdRule]"
            outlined
            item-text="name"
            item-value="name"
            prepend-icon="mdi-close"
            @click:prepend="clickDelRow(i)"
            return-object
          ></v-autocomplete>

          <v-text-field
            v-model="tokuiList[i].abbreviation"
            :label="'略称'"
            outlined
            :rules="[Rules.Required, abbreviationRule]"
            item-text="abbreviation"
            item-value="abbreviation"
          />

          <v-text-field
            v-model="tokuiList[i].ckbn"
            class="mr-5"
            :label="'区分'"
            outlined
            readonly
          />
        </v-row>
        <v-btn rounded color="primary" small @click="addRow" :disabled="!edit">
          <v-icon>mdi-plus</v-icon>得意先追加
        </v-btn>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import TokuiGroup from "@/models/TokuiGroup";

export default {
  name: "TokuiGroupDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      groupId: "",
      groupName: "",
      tokuiId: "",
      name: "",
      tokui: [],
      idList: [],
      tokuiList: [{}],
      onflg: true,
      subHeaders: [],
      subDetails: [],
      headers: ["得意先", "略称", "区分"],
      duplicate: value => {
        const tokuiIdArr = this.tokuiList.map(ele => ele.tokuiId);
        return (
          tokuiIdArr.indexOf(value.tokuiId) ===
            tokuiIdArr.lastIndexOf(value.tokuiId) || "得意先が重複しています。"
        );
      },
      ckbnRule: () => {
        const tokuiList = this.tokuiList.filter(ele => ele.ckbn !== undefined);
        const ckbnArr = tokuiList.map(ele => ele.ckbn);
        const set = new Set(ckbnArr);
        return set.size === 1 || "区分を統一してください。";
      },
      tokuiIdRule: () => {
        const records = this.params.records.filter(
          ele => ele.groupId !== this.groupId
        );
        const otherIdArr = records.map(ele => ele.tokuiIdArr.split(",")).flat();
        const selfIdArr = this.tokuiList.map(ele => ele.tokuiId);
        const allready = selfIdArr.some(self =>
          otherIdArr.some(other => Number(other) === self)
        );
        return !allready || "すでに登録されている区分です。";
      },
      groupNameRule: value => {
        const errorValue = "グループ名を８０文字以内で入力してください。";
        return value.length <= 80 || errorValue;
      },
      abbreviationRule: value => {
        const errorValue = "略称を５０文字以内で入力してください。";
        return value.length <= 80 || errorValue;
      }
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    },
    complete() {
      console.log("complete", this.userId);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.id);
      this.$loading();

      const tokuiCheck = this.tokuiList.map(ele => Object.keys(ele).length);
      if (tokuiCheck.indexOf(0) !== -1) {
        await this.$error("得意先を選択してください。");
        this.$unloading();
        return;
      }

      try {
        this.getGroupId();
        const param = new TokuiGroup(
          this.groupId,
          this.groupName,
          this.tokuiList.map((ele, index) => {
            return {
              tokuiId: ele.tokuiId,
              name: ele.name,
              abbreviation: ele.abbreviation,
              order: index + 1
            };
          })
        );

        console.log("submit_param", param);
        if (this.add) {
          await this.$post(this.Paths.tokuiGroup, param);
        } else {
          await this.$put(this.Paths.tokuiGroup, param);
        }
        this.$info("更新しました。", "得意先グループマスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async clickDelRow(i) {
      if (this.tokuiList.length - 1 <= 0) {
        await this.$error("必ず１行以上入力してください");
        return;
      }
      if (this.tokuiList[i].tokuiId) {
        const msg = `得意先 ${i + 1} を削除します。\nよろしいですか？`;
        if (!(await this.$deleteConfirm(msg))) return;
      }
      this.tokuiList.splice(i, 1);
    },
    addRow() {
      this.tokuiList.push({});
    },
    back() {
      const path = "/list/tokuiGroup";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getCodeMasterItems() {
      console.log("getExist");
      const pulldown = await this.$get(this.Paths.tokuiGroupPullDown);
      console.log("pulldown", pulldown);
      this.tokui = pulldown.map(ele => {
        return {
          tokuiId: ele.id,
          name: ele.nm1 + ele.nm2 + "：" + ele.code,
          ckbn: ele.ckbnName,
          abbreviation: ele.abbreviation,
          order: ele.order
        };
      });
    },
    groupIdChange(id) {
      const record = this.params.records.find(ele => ele.groupId === id);
      console.log("record", record);
      this.groupId = id;
      this.groupName = record.name;
      const name = record.nameArr;
      const tokuiId = record.tokuiIdArr;
      const abbreviation = record.abbreviationArr;
      const order = record.orderARR;
      if (name !== null && tokuiId !== null && abbreviation !== null) {
        this.tokuiList = [];
        const tokuiIdList = tokuiId.split(",").map(e => Number(e));
        console.log("tokuiIdList", tokuiIdList);
        //グループに紐づいている得意先を取得
        this.tokuiList = this.tokui.filter(e =>
          tokuiIdList.includes(e.tokuiId)
        );
        console.log("tokuiLst", this.tokuiList);
      }
    },
    async getGroupId() {
      let index = 1;
      const id = await this.$get(this.Paths.tokuiGroupId);
      const idArr = id.map(ele => ele.groupId);
      if (idArr.length !== 0) {
        index = Math.max(...idArr) + 1;
      }
      this.idList = [index];
      this.groupId = index;
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;
    console.log("params", this.params);
    console.log("filter", this.params.filter);
    const test = this.params.args;
    console.log("args", test);

    await this.getCodeMasterItems();

    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      if (!this.add) {
        this.idList = this.params.records.map(ele => ele.groupId);
        this.groupIdChange(test.groupId);
      } else {
        this.getGroupId();
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
