export default class TokuiGroup {
  groupId: string;
  groupName: string;
  tokuiList: Array<{
    tokuiId: string;
    name: string;
    abbreviation: string;
  }>;

  constructor(groupId = "", groupName = "", tokuiList = []) {
    this.groupId = groupId;
    this.groupName = groupName;
    this.tokuiList = tokuiList;
  }
}
